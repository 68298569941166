var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{staticClass:"main-overlay",attrs:{"fixed":"","show":_vm.showen,"z-index":"1000","no-wrap":"","opacity":"0.7","no-center":true},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"show-post"},[_c('div',{staticClass:"side-post"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require('@/assets/images/pages/classRoom/boards/posts/showPost/logoFS.png'),"alt":"logo-img"}}),(_vm.data.title)?_c('small',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.data.list.title.split("").length >= 19 ? _vm.data.list.title.substring(0, 19) : _vm.data.list.title)+" ")]):_vm._e()]),_c('div',{staticClass:"arrows"},[(_vm.currentIndex != 0)?_c('div',{staticClass:"arrow",on:{"click":_vm.prev}},[_c('img',{attrs:{"src":require('@/assets/images/pages/classRoom/boards/posts/showPost/arrow.png'),"alt":""}})]):_vm._e(),_c('span',{staticClass:"ml-1 mr-1"},[_vm._v(_vm._s(_vm.currentIndex + 1)+":"+_vm._s(_vm.listPosts.length))]),(_vm.currentIndex != _vm.listPosts.length - 1)?_c('div',{staticClass:"arrow",on:{"click":_vm.next}},[_c('img',{attrs:{"src":require('@/assets/images/pages/classRoom/boards/posts/showPost/arrow.png'),"alt":""}})]):_vm._e()]),_c('div',{staticClass:"desc"},[_c('div',{staticClass:"teacher-info"},[_c('img',{attrs:{"src":_vm.data.user &&
                    _vm.data.user.media.length > 0 &&
                    _vm.$helpers.filterImages(
                      _vm.$helpers.uiAvatar(_vm.data.user.first_name),
                      'path',
                      _vm.data.user.media
                    ),"alt":""}}),_c('div',{staticClass:"text"},[_c('span',[_vm._v(" "+_vm._s(_vm.getCreatorFullName)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.timeConvertFunction(_vm.data.created_at))+" ")])])]),(Array.isArray(_vm.data.media) && _vm.data.media.length != 0)?_c('div',{staticClass:"course-info"},[_c('div',[_vm._v(_vm._s(_vm.data.title))]),_c('div',[_vm._v(" "+_vm._s(_vm.data.text)+" ")])]):_vm._e(),_c('div',{staticClass:"comments-section"},[_c('div',{staticClass:"dash"}),_vm._l((_vm.comments),function(comment){return _c('div',{key:comment.id,staticClass:"comment"},[_c('div',{staticClass:"info"},[_c('img',{attrs:{"src":comment.user &&
                        comment.user.media.length > 0 &&
                        _vm.$helpers.filterImages(
                          _vm.$helpers.uiAvatar(comment.user.first_name),
                          'path',
                          comment.user.media
                        ),"alt":comment.user.first_name}}),_c('div',{staticClass:"text"},[_c('span',[_vm._v(_vm._s(comment.user.first_name)+" "+_vm._s(comment.user.last_name)+" "),(comment.user.id === _vm.data.user.id)?_c('span',{staticClass:"user-type"},[_vm._v("Creator")]):_vm._e(),_c('button',{staticClass:"delete-btn",on:{"click":function($event){return _vm.deleteComment(comment.id)}}},[_c('feather-icon',{staticClass:"text-body d-flex align-self-end delete-icon",attrs:{"icon":"Trash2Icon","size":"18"}})],1)]),_c('span',[_vm._v(_vm._s(_vm.timeConvertFunction(comment.created_at)))])]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(comment.comment)+" ")])])])})],2)])]),(_vm.commentPermission.store)?_c('div',{staticClass:"add-comment-form"},[_c('validation-observer',{ref:"commentForm"},[_c('b-form',[_c('span',{staticClass:"d-flex col"},[_c('img',{attrs:{"src":[].concat( _vm.userData.media ).length != 0
                        ? _vm.userData.media[0].path
                        : require('@/assets/images/pages/classRoom/boards/posts/showPost/dummyPerson.png'),"alt":""}})]),_c('span',{staticClass:"d-flex col-6 col"},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required"}},[_c('b-form-input',{staticClass:"input",attrs:{"placeholder":_vm.$t('g.writeComment')},model:{value:(_vm.commentToSent),callback:function ($$v) {_vm.commentToSent=$$v},expression:"commentToSent"}})],1)],1)],1),_c('span',{staticClass:"col mt-n5"},[_c('img',{attrs:{"src":require('@/assets/images/pages/classRoom/boards/posts/showPost/paper-airplane.png'),"alt":"dummy"},on:{"click":_vm.addComentREquest}})])])],1)],1):_vm._e(),_c('overlay-component',{attrs:{"isLoading":_vm.isLoading}})],1),_c('div',{staticClass:"preview-section"},[_c('b-row',{staticClass:"col-1 x-row"},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require('@/assets/images/pages/classRoom/boards/posts/showPost/x.png'),"alt":""},on:{"click":function($event){return _vm.close()}}})]),(Array.isArray(_vm.data.media) && _vm.data.media.length != 0)?_c('b-row',{staticClass:"main-img-preview"},[_c('img',{staticClass:"main-img",attrs:{"src":_vm.data.media[0].path,"alt":""}})]):_c('b-row',[_c('div',{staticClass:"description-div"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.data.text)+" ")])])])],1)])]},proxy:true}])}),_c('report-component',{attrs:{"reportableId":_vm.report.reportable_id,"reportableType":_vm.report.reportable_type}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }